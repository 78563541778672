<template>
  <b-modal
    id="modal-configuracionMedico"
    :title="'Configurar Médico: '+nameMedico"
    size='lg'
    hide-footer
    @show="onOpenModal"
    @hidden="clearForm"
  >
    <div class="row mx-0 py-3 bg-gray">
      <div class="col-sm-7">
        <div class="row mb-2">
          <div class="col-sm-4 pr-0 text-right">
            <label class="col-form-label">Actividad Econom.:
              <span class="text-danger">*</span>
            </label>
          </div>
          <div class="col-sm-8">
            <multiselect2
              v-model="actividadEconomicaSelected"
              :options="listaActividadesEconomicas"
              placeholder=""
              label="descripcion"
              track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect2>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-4 pr-0 text-right">
            <label class="col-form-label">Producto/Servicio:
              <span class="text-danger">*</span>
            </label>
          </div>
          <div class="col-sm-8">
            <multiselect2
              v-model="productoServicioSelected"
              :options="listaProductosServicios"
              placeholder=""
              label="descripcion"
              track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect2>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-4 pr-0 text-right">
            <label class="col-form-label">Moneda Principal:
              <span class="text-danger">*</span>
            </label>
          </div>
          <div class="col-sm-8">
            <multiselect2
              v-model="monedaPrincipalSelected"
              :options="listaMonedas"
              placeholder=""
              label="descripcion"
              track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect2>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-4 pr-0 text-right">
            <label class="col-form-label">Moneda Secundaria:
              <span class="text-danger">*</span>
            </label>
          </div>
          <div class="col-sm-8">
            <multiselect2
              v-model="monedaSecundariaSelected"
              :options="listaMonedas"
              placeholder=""
              label="descripcion"
              track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect2>
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="row mb-2">
          <div class="col-sm-4 pr-0 text-right">
            <label class="col-form-label">Sucursal.:
              <span class="text-danger">*</span>
            </label>
          </div>
          <div class="col-sm-8">
            <multiselect2
              v-model="sucursalSelected"
              :options="listaSucursales"
              placeholder=""
              label="nombre_comercial"
              track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect2>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-4 px-0 text-right">
            <label class="col-form-label">Punto de Venta:
              <span class="text-danger float-right">*</span>
            </label>
          </div>
          <div class="col-sm-8">
            <multiselect2
              v-model="puntoVentaSelected"
              :options="listaPuntosVenta"
              placeholder=""
              label="nombre_punto_venta"
              track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect2>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-4 pr-0 text-right">
            <label class="col-form-label">U.M.:
              <span class="text-danger">*</span>
            </label>
          </div>
          <div class="col-sm-8">
            <multiselect2
              v-model="unidadMedidaSelected"
              :options="listaUM"
              placeholder=""
              label="descripcion"
              track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect2>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-4 pr-0 text-right">
            <label class="col-form-label">País:
              <span class="text-danger">*</span>
            </label>
          </div>
          <div class="col-sm-8">
            <multiselect2
              v-model="paisSelected"
              :options="listaPaises"
              placeholder=""
              label="descripcion"
              track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect2>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 py-4">
      <div class="col-sm-6 bg-gray">
        <div class="row mb-2">
          <div class="col-sm-12 text-left">
            <label class="col-form-label font-weight-bold">Medio de Pago:</label>
          </div>
        </div>
        <div class="row mb-2" v-for="(pago) in listaMediosPagoModal" :key="pago.id">
          <div class="col-sm-5 pr-0 text-right">
            <label class="col-form-label">{{pago.nombre}}:</label>
          </div>
          <div class="col-sm-7">
            <multiselect2
              v-model="pago.medio_pago_siat"
              :options="listaMedioPagosSiat"
              placeholder=""
              label="descripcion"
              track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect2>
          </div>
        </div>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-5 bg-gray">
        <div class="row mb-2">
          <div class="col-sm-12 text-left">
            <label class="col-form-label font-weight-bold">Tipo de Documento:</label>
          </div>
        </div>
        <div class="row mb-2" v-for="(tipoDoc) in listaTipoDocumentosModal" :key="tipoDoc.id">
          <div class="col-sm-4 pr-0 text-right">
            <label class="col-form-label">{{tipoDoc.abreviacion}}:</label>
          </div>
          <div class="col-sm-7">
            <multiselect2
              v-model="tipoDoc.tipo_documento_siat"
              :options="listaTipoDocumentosSiat"
              placeholder=""
              label="descripcion"
              track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect2>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-1">
      <div class="col-sm-12 text-right">
        <button
          class="btn btn-outline-dark mr-4"
          @click="cancelar"
        >
          Cancelar
        </button>
        <button
          class="btn btn-success"
          @click="guardarModal"
        >
          Guardar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect2 from '@/components/util/vue-multiselect2';
import { util } from '@/plugins/util';

export default {
  name: 'ModalConfiguracionMedico',
  components: {
    Multiselect2,
  },
  props: {
    nameMedico: {
      type: String,
      required: true,
    },
    listaActividadesEconomicas: {
      type: Array,
      required: true,
    },
    listaProductosServicios: {
      type: Array,
      required: true,
    },
    listaMonedas: {
      type: Array,
      required: true,
    },
    listaSucursales: {
      type: Array,
      required: true,
    },
    listaPuntosVenta: {
      type: Array,
      required: true,
    },
    listaUM: {
      type: Array,
      required: true,
    },
    listaPaises: {
      type: Array,
      required: true,
    },
    listaMedioPagos: {
      type: Array,
      required: true,
    },
    listaMedioPagosSiat: {
      type: Array,
      required: true,
    },
    listaTipoDocumentos: {
      type: Array,
      required: true,
    },
    listaTipoDocumentosSiat: {
      type: Array,
      required: true,
    },
    configuracionMedicoInput: {
      type: Object,
      default: null,
    },
    dataTipoDocumentoMedicoInput: {
      type: Array,
      default: null,
    },
    dataMedioPagoInput: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      actividadEconomicaSelected: null,
      productoServicioSelected: null,
      monedaPrincipalSelected: null,
      monedaSecundariaSelected: null,
      sucursalSelected: null,
      puntoVentaSelected: null,
      unidadMedidaSelected: null,
      paisSelected: null,
      listaMediosPagoModal: [],
      listaTipoDocumentosModal: [],
    };
  },
  methods: {
    onOpenModal() {
      this.refreshDataModal();
      this.refreshListaMediosPagoModal();
      this.refreshListaTipoDocumentosModal();
    },
    clearForm() {
      this.actividadEconomicaSelected = null;
      this.productoServicioSelected = null;
      this.monedaPrincipalSelected = null;
      this.monedaSecundariaSelected = null;
      this.sucursalSelected = null;
      this.puntoVentaSelected = null;
      this.unidadMedidaSelected = null;
      this.paisSelected = null;
    },
    refreshDataModal() {
      if (this.configuracionMedicoInput !== null) {
        const data = this.configuracionMedicoInput;
        this.actividadEconomicaSelected = this.listaActividadesEconomicas.find((item) => item.id === data.siat_actividad_economica_id) ?? null;
        this.productoServicioSelected = this.listaProductosServicios.find((item) => item.id === data.siat_producto_servicio_id) ?? null;
        this.monedaPrincipalSelected = this.listaMonedas.find((item) => item.id === data.siat_moneda_principal_id) ?? null;
        this.monedaSecundariaSelected = this.listaMonedas.find((item) => item.id === data.siat_moneda_secundaria_id) ?? null;
        this.sucursalSelected = this.listaSucursales.find((item) => item.id === data.siat_sucursal_id) ?? null;
        this.puntoVentaSelected = this.listaPuntosVenta.find((item) => item.id === data.siat_punto_venta_id) ?? null;
        this.unidadMedidaSelected = this.listaUM.find((item) => item.id === data.siat_unidad_medida_id) ?? null;
        this.paisSelected = this.listaPaises.find((item) => item.id === data.siat_pais_id) ?? null;
      }
    },
    refreshListaMediosPagoModal() {
      this.listaMediosPagoModal = this.listaMedioPagos.map((medioPago) => {
        const item = this.dataMedioPagoInput.find((el) => el.medio_pago_id === medioPago.id) ?? null;
        let medioPagoSiat = null;
        if (item !== null) {
          medioPagoSiat = this.listaMedioPagosSiat.find((el) => el.id === item.siat_medio_pago_id) ?? null;
        }
        return {
          ...medioPago,
          medio_pago_siat: medioPagoSiat,
          medio_pago_siat_medico_id: item ? item.id : 0,
        };
      });
    },
    refreshListaTipoDocumentosModal() {
      this.listaTipoDocumentosModal = this.listaTipoDocumentos.map((tipoDoc) => {
        const item = this.dataTipoDocumentoMedicoInput.find((el) => el.tipo_documento_id === tipoDoc.id) ?? null;
        let tipoDocumentoSiat = null;
        if (item !== null) {
          tipoDocumentoSiat = this.listaTipoDocumentosSiat.find((el) => el.id === item.siat_tipo_documento_id) ?? null;
        }
        return {
          ...tipoDoc,
          tipo_documento_siat: tipoDocumentoSiat,
          tipo_documento_siat_medico_id: item ? item.id : 0,
        };
      });
    },
    guardarModal() {
      if (this.actividadEconomicaSelected === null) {
        util.showNotify('El campo Actividad Económica es requerido.', 'warn');
        return;
      }
      if (this.productoServicioSelected === null) {
        util.showNotify('El campo Producto/Servicio es requerido.', 'warn');
        return;
      }
      if (this.monedaPrincipalSelected === null) {
        util.showNotify('El campo Moneda Principal es requerido.', 'warn');
        return;
      }
      if (this.monedaSecundariaSelected === null) {
        util.showNotify('El campo Moneda Secundaria es requerido.', 'warn');
        return;
      }
      if (this.sucursalSelected === null) {
        util.showNotify('El campo Sucursal es requerido.', 'warn');
        return;
      }
      if (this.puntoVentaSelected === null) {
        util.showNotify('El campo Punto de Venta es requerido.', 'warn');
        return;
      }
      if (this.unidadMedidaSelected === null) {
        util.showNotify('El campo U.M es requerido.', 'warn');
        return;
      }
      if (this.paisSelected === null) {
        util.showNotify('El campo País es requerido.', 'warn');
        return;
      }
      const dataConfigMedico = {
        id: this.configuracionMedicoInput ? this.configuracionMedicoInput.id : 0,
        siat_actividad_economica_id: this.actividadEconomicaSelected.id,
        siat_producto_servicio_id: this.productoServicioSelected.id,
        siat_moneda_principal_id: this.monedaPrincipalSelected.id,
        siat_moneda_secundaria_id: this.monedaSecundariaSelected.id,
        siat_sucursal_id: this.sucursalSelected.id,
        siat_punto_venta_id: this.puntoVentaSelected.id,
        siat_unidad_medida_id: this.unidadMedidaSelected.id,
        siat_pais_id: this.paisSelected.id,
        siat_nombre_actividad_economica: this.actividadEconomicaSelected.descripcion,
        siat_nombre_producto_servicio: this.productoServicioSelected.descripcion,
        siat_nombre_moneda_principal: this.monedaPrincipalSelected.descripcion,
        siat_nombre_moneda_secundaria: this.monedaSecundariaSelected.descripcion,
        siat_nombre_sucursal: this.sucursalSelected.nombre_comercial,
        siat_nombre_punto_venta: this.puntoVentaSelected.nombre_punto_venta,
        siat_nombre_unidad_medida: this.unidadMedidaSelected.descripcion,
        siat_nombre_pais: this.paisSelected.descripcion,
      };

      const dataMedioPago = this.listaMediosPagoModal.map((item) => ({
        id: item.medio_pago_siat_medico_id,
        medio_pago_id: item.id,
        siat_medio_pago_id: item.medio_pago_siat ? item.medio_pago_siat.id : null,
        siat_nombre_medio_pago: item.nombre,
      }));
      const dataTipoDoc = this.listaTipoDocumentosModal.map((item) => ({
        id: item.tipo_documento_siat_medico_id,
        tipo_documento_id: item.id,
        siat_tipo_documento_id: item.tipo_documento_siat ? item.tipo_documento_siat.id : null,
        siat_nombre_tipo_documento: item.abreviacion,
      }));
      this.$emit('updateDataConfiguracionMedico', dataConfigMedico);
      this.$emit('updateDataMedioPago', dataMedioPago);
      this.$emit('updateDataTipoDocumento', dataTipoDoc);
      this.$bvModal.hide('modal-configuracionMedico');
    },
    cancelar() {
      this.$bvModal.hide('modal-configuracionMedico');
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background: rgba(237,238, 238, 1);
}
</style>
